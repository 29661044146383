import Vue from 'vue'
import Vuex from 'vuex'

import careerOrientationModule from './careerOrientationModule'
import clientsModule from './clientsModule'
import gamificationModule from './gamificationModule'
import newsModule from './newsModule'
import eventsModule from './eventsModule'
import stationsModule from './stationsModule'
import usersModule from './usersModule'
import questsModule from './questsModule'
import notificationsBubbleModule from './notificationsBubbleModule'
import sekundarstufenModule from './sekundarstufenModule'
import cmsModule from './cmsModule'
import archiveModule from './archiveModule'
import importantNotificationModule from './importantNotificationModule'
import signaturesModule from './signaturesModule'
import schoolTypesModule from './schoolTypesModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    careerOrientationModule,
    clientsModule,
    gamificationModule,
    newsModule,
    stationsModule,
    usersModule,
    eventsModule,
    questsModule,
    notificationsBubbleModule,
    sekundarstufenModule,
    cmsModule,
    archiveModule,
    importantNotificationModule,
    signaturesModule,
    schoolTypesModule
  }
})
