export default {
  schoolTypes: {
    fetching: false,
    fetched: false,
    data: []
  },
  schoolType: {
    fetching: false,
    fetched: false,
    submitting: false,
    submitted: false,
    data: null
  }
}
