import router from '@/router'

export function GET_SCHOOL_TYPES (state) {
  state.schoolTypes.fetching = true
  state.schoolTypes.fetched = false
}

export function RECEIVE_SCHOOL_TYPES (state, schoolTypes) {
  state.schoolTypes.fetching = false
  state.schoolTypes.fetched = true
  state.schoolTypes.data = schoolTypes
}

export function GET_SCHOOL_TYPE (state) {
  state.schoolType.fetching = true
  state.schoolType.fetched = false
}

export function RECEIVE_SCHOOL_TYPE (state, schoolType) {
  state.schoolType.fetching = false
  state.schoolType.fetched = true
  state.schoolType.data = schoolType
}

export function STORE_SCHOOL_TYPE (state) {
  state.schoolType.submitting = true
  state.schoolType.submitted = false
  state.schoolType.data = null
}

export function SCHOOL_TYPE_STORED (state, schoolType) {
  state.schoolType.submitting = false
  state.schoolType.submitted = true
  state.schoolType.data = schoolType
  router.push({ name: 'SchoolTypes', params: { uuid: schoolType.uuid } })
}

export function UPDATE_SCHOOL_TYPE (state) {
  state.schoolType.submitting = true
  state.schoolType.submitted = false
  state.schoolType.data = null
}

export function SCHOOL_TYPE_UPDATED (state, schoolType) {
  state.schoolType.submitting = false
  state.schoolType.submitted = true
  state.schoolType.data = schoolType
}

export function DELETE_SCHOOL_TYPE (state) {
  state.schoolType.submitting = true
  state.schoolType.submitted = false
}

export function SCHOOL_TYPE_DELETED (state, uuid) {
  state.schoolType.submitting = false
  state.schoolType.submitted = true
  state.schoolType.data = state.schoolTypes.data.filter(el => el.uuid !== uuid)
}

export function SCHOOL_TYPE_ERROR (state, err) {
  if (state.schoolType.fetching) state.schoolType.fetching = false
  if (state.schoolTypes.fetching) state.schoolTypes.fetching = false
  if (state.schoolType.submitting) state.schoolType.submitting = false
  this.state.errors.push(err)
}

export function SCHOOL_TYPE_EXACT_ERROR (state) {
  if (state.schoolType.fetching) state.schoolType.fetching = false
  if (state.schoolTypes.fetching) state.schoolTypes.fetching = false
  if (state.schoolType.submitting) state.schoolType.submitting = false
}
