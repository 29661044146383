import axios from 'axios'

export function getAll ({ commit }) {
  commit('GET_SCHOOL_TYPES')
  axios.get('/api/identity/v1/schooltypes').then(response => {
    commit('RECEIVE_SCHOOL_TYPES', response.data)
  }).catch(err => {
    const msg = !err ? 'Unknown error' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
    commit('SCHOOL_TYPE_ERROR', msg)
  })
}
